import { format } from "date-fns";

const TOKEN_NAME = "persatuanbulutangkis_token";

export const setToken = (token: any) => {
  localStorage.setItem(TOKEN_NAME, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
};

export const formatNumber = (number: number | undefined) => {
  if (!number) return 0;
  return number.toLocaleString("id-ID");
};

export const formatDate = (date: Date, formatAs = "dd MMM yyyy") => {
  return format(new Date(date), formatAs);
};

export const gramToKilogram = (number: number | undefined) => {
  if (!number) return 0;

  if (number < 100) return `${number} g`;
  return `${number / 1000} kg`;
};
