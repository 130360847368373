import "./tabs.css";

import {
  homeOutline,
  medalOutline,
  listOutline,
  personOutline,
  cartOutline,
} from "ionicons/icons";
import { Link, Redirect, Route, useHistory } from "react-router-dom";

import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

import {
  ATTRIBUTE,
  HOME,
  LOGIN,
  ORDER,
  PROFILE,
  TABS,
  TOURNAMENT,
} from "../../shared/constant/routes";
import HomePage from "../home";
import ProfilePage from "../profile";
import TournamentPage from "../tournament";
import OrderPage from "../order";
import { selectIsAuthenticated } from "../../redux/features/account/accountSlice";
import { useAppSelector } from "../../redux/hooks";

const TabsPage: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthenticated);

  return (
    <IonContent>
      <IonFab
        className="pb-4"
        slot="fixed"
        vertical="bottom"
        horizontal="center"
      >
        <Link to={ATTRIBUTE}>
          <IonFabButton color="secondary">
            <IonIcon icon={cartOutline} />
          </IonFabButton>
        </Link>
      </IonFab>
      <IonTabs>
        <IonRouterOutlet>
          <Redirect exact path={TABS} to={`${TABS}${HOME}`} />
          <Route exact path={`${TABS}${HOME}`}>
            <HomePage />
          </Route>
          <Route exact path={`${TABS}${TOURNAMENT}`}>
            <TournamentPage />
          </Route>
          <Route
            exact
            path={`${TABS}${ORDER}`}
            render={() => (isAuth ? <OrderPage /> : <Redirect to={LOGIN} />)}
          ></Route>
          <Route
            exact
            path={`${TABS}${PROFILE}`}
            render={() => (isAuth ? <ProfilePage /> : <Redirect to={LOGIN} />)}
          ></Route>
          <Route exact path={TABS}>
            <Redirect to={`${TABS}${HOME}`} />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" color={"primary"}>
          <IonTabButton tab="home" href={`${TABS}${HOME}`}>
            <IonIcon icon={homeOutline} />
            <IonLabel>Beranda</IonLabel>
          </IonTabButton>
          <IonTabButton
            className="mr-6"
            tab="tournament"
            href={`${TABS}${TOURNAMENT}`}
          >
            <IonIcon icon={medalOutline} />
            <IonLabel>Turnamen</IonLabel>
          </IonTabButton>
          <IonTabButton className="ml-6" tab="order" href={`${TABS}${ORDER}`}>
            <IonIcon icon={listOutline} />
            <IonLabel>Transaksi</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href={`${TABS}${PROFILE}`}>
            <IonIcon icon={personOutline} />
            <IonLabel>Profil</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonContent>
  );
};

export default TabsPage;
