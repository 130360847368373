import { FC } from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonToggle,
} from "@ionic/react";
import { Controller, DeepMap, FieldError } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface RadioItem {
  label: string;
  value: string;
}

interface Props {
  name: string;
  control?: any;
  label?: string;
  radioItems: RadioItem[];
  note?: string;
  rules?: Object;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

export const Radio: FC<Props> = ({
  name,
  control,
  label,
  radioItems,
  note,
  rules,
  errors,
}) => {
  return (
    <>
      <IonItem>
        <IonLabel position="fixed">{label ?? name}</IonLabel>
        <Controller
          render={({ field, field: { onChange } }) => (
            <IonList>
              <IonRadioGroup
                value={field.value}
                onIonChange={onChange}
                aria-invalid={errors && errors[name] ? "true" : "false"}
                aria-describedby={`${name}Error`}
              >
                {radioItems.map((item, i) => (
                  <IonItem key={i}>
                    <IonLabel>{item.label}</IonLabel>
                    <IonRadio slot="end" value={item.value}></IonRadio>
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )}
          control={control}
          name={name}
          rules={rules}
        />
        {note ? <IonNote slot="helper">{note}</IonNote> : <></>}
      </IonItem>
      <ErrorMessage
        errors={errors}
        name={name}
        as={<IonNote slot="error" color="danger" />}
      />
    </>
  );
};
