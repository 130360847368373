import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import LoginPage from "../pages/login";
import RegisterPage from "../pages/register";
import TabPage from "../pages/tabs";
import EventDetailPage from "../pages/event-detail";
import AttendedEventPage from "../pages/attended-event";
import TournamentDetailPage from "../pages/tournament-detail";
import ParticipatedTournamentPage from "../pages/participated-tournament";
import AttributePage from "../pages/attribute";
import AttributeDetailPage from "../pages/attribute-detail";
import CartPage from "../pages/cart";
import OrderDetailPage from "../pages/order-detail";
import EditProfilePage from "../pages/edit-profile";
import MemberCardPage from "../pages/member-card";
import CommunityPage from "../pages/community";
import { selectIsAuthenticated } from "../redux/features/account/accountSlice";
import { useAppSelector } from "../redux/hooks";
import {
  LOGIN,
  TABS,
  EVENT,
  TOURNAMENT,
  ATTRIBUTE,
  ORDER,
  CART,
  PROFILE,
  MEMBER_CARD,
  ATTENDED_EVENT,
  HOME,
  COMMUNITY,
  PARTICIPATED_TOURNAMENT,
  REGISTER,
} from "../shared/constant/routes";

const Router: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthenticated);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={`${TABS}${HOME}`} />}
            />
            <Route exact path={LOGIN} component={LoginPage} />
            <Route exact path={REGISTER} component={RegisterPage} />
            {/* tab no login */}
            <Route path={TABS} render={() => <TabPage />} />
            {/* community info no login */}
            <Route path={COMMUNITY} render={() => <CommunityPage />} />
            {/* event detail no login */}
            <Route path={`${EVENT}/:id`} render={() => <EventDetailPage />} />
            {/* event attended */}
            <Route
              path={ATTENDED_EVENT}
              render={() =>
                isAuth ? <AttendedEventPage /> : <Redirect to={LOGIN} />
              }
            />
            {/* tournament detail no login */}
            <Route
              path={`${TOURNAMENT}/:id`}
              render={() => <TournamentDetailPage />}
            />
            <Route
              path={PARTICIPATED_TOURNAMENT}
              render={() =>
                isAuth ? <ParticipatedTournamentPage /> : <Redirect to={LOGIN} />
              }
            />
            {/* attribute & detail */}
            <Route
              path={`${ATTRIBUTE}/:id`}
              render={() =>
                isAuth ? <AttributeDetailPage /> : <Redirect to={LOGIN} />
              }
            />
            <Route
              path={ATTRIBUTE}
              render={() =>
                isAuth ? <AttributePage /> : <Redirect to={LOGIN} />
              }
            />
            {/* order detail */}
            <Route
              path={`${ORDER}/:id`}
              render={() =>
                isAuth ? <OrderDetailPage /> : <Redirect to={LOGIN} />
              }
            />
            {/* cart */}
            <Route
              path={CART}
              render={() => (isAuth ? <CartPage /> : <Redirect to={LOGIN} />)}
            />
            {/* edit profile & member card*/}
            <Route
              path={`${PROFILE}/edit`}
              render={() =>
                isAuth ? <EditProfilePage /> : <Redirect to={LOGIN} />
              }
            />
            <Route
              path={MEMBER_CARD}
              render={() =>
                isAuth ? <MemberCardPage /> : <Redirect to={LOGIN} />
              }
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default Router;
