import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import { Payment } from "../../../shared/interfaces";
import { AppState } from "../../store";

export interface PaymentState {
  item: Payment | null;
  status: { get: string; post: string };
  error: { get: string | null; post: string | null };
}

const initialState: PaymentState = {
  item: null,
  status: { get: "idle", post: "idle" },
  error: { get: null, post: null },
};

export const getPayment = createAsyncThunk(
  "payments/getPayment",
  async (id: number) => {
    const response = (await client.get(`payment/${id}`)).data;
    return response.data.payment;
  }
);

export const postPayment = createAsyncThunk(
  "payments/postPayment",
  async (payload: any) => {
    const response = (await client.post(`payment`, payload)).data;
    return response.data.payment;
  }
);

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    resetPayment(state) {
      state.status = {
        get: "idle",
        post: "idle",
      };
      state.error = {
        get: null,
        post: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPayment.pending, (state, action) => {
        state.status.get = "loading";
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.status.get = "success";
        state.item = action.payload;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.status.get = "failed";
        state.error.get = action.error.message ?? null;
      })

      .addCase(postPayment.pending, (state, action) => {
        state.status.post = "loading";
      })
      .addCase(postPayment.fulfilled, (state, action) => {
        state.status.post = "success";
        state.item = action.payload;
      })
      .addCase(postPayment.rejected, (state, action) => {
        state.status.post = "failed";
        state.error.post = action.error.message ?? null;
      });
  },
});

export const selectPayment = (state: AppState) => state.payment.item;
export const selectPaymentStatus = (state: AppState) => state.payment.status;
export const selectPaymentError = (state: AppState) => state.payment.error;

export const { resetPayment } = paymentsSlice.actions;

export default paymentsSlice.reducer;
