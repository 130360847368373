import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import {
  AttendedPayload,
  Event,
  EventAttendance,
  ValidationError,
} from "../../../shared/interfaces";
import { AppState } from "../../store";

export interface EventState {
  list: Event[];
  item: Event | null;
  attended: EventAttendance[];
  attended_item: EventAttendance | null;
  status: {
    list: string;
    item: string;
    attended: string;
    attended_item: string;
    attended_post: string;
    attended_update: string;
  };
  error: {
    list: string | null;
    item: string | null;
    attended: string | null;
    attended_item: string | null;
    attended_post: string | ValidationError | null;
    attended_update: string | ValidationError | null;
  };
}

const initialState: EventState = {
  list: [],
  item: null,
  attended: [],
  attended_item: null,
  status: {
    list: "idle",
    item: "idle",
    attended: "idle",
    attended_item: "idle",
    attended_post: "idle",
    attended_update: "idle",
  },
  error: {
    list: null,
    item: null,
    attended: null,
    attended_item: null,
    attended_post: null,
    attended_update: null,
  },
};

export const getEvents = createAsyncThunk("events/getEvents", async () => {
  const response = (await client.get("event")).data;
  return response.data.event;
});

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async (id: number) => {
    const response = (await client.get(`event/${id}`)).data;
    return response.data.event;
  }
);

export const getAttendedEvents = createAsyncThunk(
  "events/getAttendedEvents",
  async () => {
    const response = (await client.get("attended")).data;
    return response.data.attended;
  }
);

export const getAttendedItem = createAsyncThunk(
  "events/getAttendedItem",
  async (id: number) => {
    const response = (await client.get(`attended/${id}`)).data;
    return response.data.attended;
  }
);

export const postAttended = createAsyncThunk(
  "events/postAttended",
  async (payload: AttendedPayload) => {
    const response = (await client.post("attended", payload)).data;
    return response;
  }
);

export const updateAttended = createAsyncThunk(
  "events/updateAttended",
  async (payload: AttendedPayload) => {
    const response = (
      await client.post(`attended/${payload.attended_id ?? ""}`, payload)
    ).data;
    return response;
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    resetEvent(state) {
      state.list = [];
      state.item = null;
      state.attended = [];
      state.attended_item = null;
      state.status = {
        list: "idle",
        item: "idle",
        attended: "idle",
        attended_item: "idle",
        attended_post: "idle",
        attended_update: "idle",
      };
      state.error = {
        list: null,
        item: null,
        attended: null,
        attended_item: null,
        attended_post: null,
        attended_update: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEvents.pending, (state, action) => {
        state.status.list = "loading";
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.status.list = "failed";
        state.error.list = action.error.message ?? null;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.status.list = "success";
        state.list = action.payload;
      })

      .addCase(getEvent.pending, (state, action) => {
        state.status.item = "loading";
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.status.item = "failed";
        state.error.item = action.error.message ?? null;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.status.item = "success";
        state.item = action.payload;
      })

      .addCase(getAttendedEvents.pending, (state, action) => {
        state.status.attended = "loading";
      })
      .addCase(getAttendedEvents.rejected, (state, action) => {
        state.status.attended = "failed";
        state.error.attended = action.error.message ?? null;
      })
      .addCase(getAttendedEvents.fulfilled, (state, action) => {
        state.status.attended = "success";
        state.attended = action.payload;
      })

      .addCase(getAttendedItem.pending, (state, action) => {
        state.status.attended_item = "loading";
      })
      .addCase(getAttendedItem.rejected, (state, action) => {
        state.status.attended_item = "failed";
        state.error.attended_item = action.error.message ?? null;
      })
      .addCase(getAttendedItem.fulfilled, (state, action) => {
        state.status.attended_item = "success";
        state.attended_item = action.payload;
      })

      .addCase(postAttended.pending, (state, action) => {
        state.status.attended_post = "loading";
      })
      .addCase(postAttended.rejected, (state, action) => {
        state.status.attended_post = "failed";
        state.error.attended_post = action.error.message ?? null;
      })
      .addCase(postAttended.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.attended_post = "success";
          state.attended_item = response.data.attended;
        } else {
          state.status.attended_post = "failed";
          state.error.attended_post =
            response.errors ?? response.message ?? null;
        }
      })

      .addCase(updateAttended.pending, (state, action) => {
        state.status.attended_update = "loading";
      })
      .addCase(updateAttended.rejected, (state, action) => {
        state.status.attended_update = "failed";
        state.error.attended_update = action.error.message ?? null;
      })
      .addCase(updateAttended.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.attended_update = "success";
          state.attended_item = response.data.attended;
        } else {
          state.status.attended_update = "failed";
          state.error.attended_update =
            response.errors ?? response.message ?? null;
        }
      });
  },
});

export const selectEvents = (state: AppState) => state.events.list;
export const selectEvent = (state: AppState) => state.events.item;
export const selectAttendedEvent = (state: AppState) => state.events.attended;
export const selectAttendedItem = (state: AppState) =>
  state.events.attended_item;
export const selectEventsStatus = (state: AppState) => state.events.status;
export const selectEventsError = (state: AppState) => state.events.error;

export const { resetEvent } = eventsSlice.actions;

export default eventsSlice.reducer;
