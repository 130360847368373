import "./home.css";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import slide1 from "../../assets/img/slide/slide1.webp";
import slide2 from "../../assets/img/slide/slide2.webp";
import slide3 from "../../assets/img/slide/slide3.webp";

import calendar from "../../assets/icon8/calendar.png";
import marker from "../../assets/icon8/marker.png";
import call from "../../assets/icon8/call.png";

import {
  getEvents,
  resetEvent,
  selectEvents,
  // selectEventsError,
  selectEventsStatus,
} from "../../redux/features/events/eventsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ATTENDED_EVENT, COMMUNITY, EVENT } from "../../shared/constant/routes";
import { EVENT_IMG_URL } from "../../shared/constant/setting";
import { formatDate } from "../../shared/utils";
import { calendarOutline, informationCircleOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import {
  getProfile,
  selectIsAuthenticated,
} from "../../redux/features/account/accountSlice";

const PAGE_TITLE = "Beranda";

const slidePromoOpts = {
  speed: 400,
  autoplay: {
    delay: 3000,
  },
};

const promo = [{ image: slide1 }, { image: slide2 }, { image: slide3 }];

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  const isAuth = useAppSelector(selectIsAuthenticated);

  const events = useAppSelector(selectEvents);
  const eventsStatus = useAppSelector(selectEventsStatus);
  // const eventsError = useAppSelector(selectEventsError);

  // get profile
  useEffect(() => {
    if (!isAuth) {
      dispatch(getProfile());
    }
  }, []);

  useEffect(() => {
    if (!isLoaded && eventsStatus.list !== "idle") dispatch(resetEvent());
  }, []);

  useEffect(() => {
    if (eventsStatus.list === "idle") {
      dispatch(getEvents());
      setIsLoaded(true);
    } else if (eventsStatus.list === "success") {
      // console.log(homeStatus);
    } else if (eventsStatus.list === "failed") {
      // console.log(homeError);
    }
  }, [eventsStatus]);

  return (
    <IonPage className="home">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                history.push(ATTENDED_EVENT);
              }}
            >
              <IonIcon icon={calendarOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="grey" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonFab className="pb-4" slot="fixed" vertical="top" horizontal="end">
          <Link to={COMMUNITY}>
            <IonFabButton color="primary">
              <IonIcon icon={informationCircleOutline} />
            </IonFabButton>
          </Link>
        </IonFab>

        <IonSlides
          className="promos-slides"
          pager={true}
          options={slidePromoOpts}
        >
          {promo.map((item, index) => {
            return (
              <IonSlide key={index}>
                <img className="slider" src={item.image} alt="img" />
              </IonSlide>
            );
          })}
        </IonSlides>

        <div className="mx-2">
          <IonTitle color="primary" className="mt-2 font-extrabold">
            Kegiatan Terbaru
          </IonTitle>
          {eventsStatus.list === "success" &&
            events.length > 0 &&
            events.map((item, index) => {
              return (
                <IonCard
                  className="my-4 rounded-2xl"
                  key={index}
                  onClick={() => {
                    history.push(`${EVENT}/${item.id}`);
                  }}
                >
                  <div className="img-container">
                    <img alt=" " src={`${EVENT_IMG_URL}/${item.banner_img}`} />
                  </div>

                  <IonCardHeader>
                    <IonCardTitle>{item.name}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={calendar} />
                        </IonCol>
                        <IonCol>
                          {formatDate(item.from)} s/d{" "}
                          {item.to ? formatDate(item.to) : "Selesai"}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={marker} />
                        </IonCol>
                        <IonCol>{item.location}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={call} />
                        </IonCol>
                        <IonCol>{item.contact_person}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
