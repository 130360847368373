import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import { Community } from "../../../shared/interfaces/index";

import type { AppState } from "../../store";
export interface CommunityState {
  item: Community | null;
  status: {
    item: string;
  };
  error: {
    item: string | null;
  };
}

const initialState: CommunityState = {
  item: null,
  status: {
    item: "idle",
  },
  error: {
    item: null,
  },
};

export const getCommunity = createAsyncThunk(
  "community/getCommunity",
  async () => {
    const response = (await client.get(`community`)).data;
    return response;
  }
);

export const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    resetCommunity(state) {
      state.status = {
        item: "idle",
      };
      state.error = {
        item: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCommunity.pending, (state, action) => {
        state.status.item = "loading";
      })
      .addCase(getCommunity.rejected, (state, action) => {
        state.status.item = "failed";
        state.error.item = action.error.message ?? null;
      })
      .addCase(getCommunity.fulfilled, (state, action) => {
        const response = action.payload;
        state.item = response.data.setting;
        state.status.item = "success";
      });
  },
});

export const selectCommunity = (state: AppState) => state.community.item;
export const selectCommunityStatus = (state: AppState) =>
  state.community.status;
export const selectCommunityError = (state: AppState) => state.community.error;

export const { resetCommunity } = communitySlice.actions;

export default communitySlice.reducer;
