import "./tournament-detail.css";

import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import { Browser } from "@capacitor/browser";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import placeholder from "../../assets/img/placeholder.png";
import {
  getParticipate,
  getTournament,
  postParticipate,
  resetTournament,
  selectParticipateItem,
  selectTournament,
  selectTournamentsError,
  selectTournamentsStatus,
} from "../../redux/features/tournaments/tournamentsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatDate } from "../../shared/utils";
import { TOURNAMENT_IMG_URL } from "../../shared/constant/setting";
import { mapOutline } from "ionicons/icons";
import useShowToast from "../../shared/hooks/useShowToast";
import { selectAccountUser, selectIsAuthenticated } from "../../redux/features/account/accountSlice";
import { ParticipatePayload } from "../../shared/interfaces";
import { useForm } from "react-hook-form";
import { Input, Radio } from "../../components/Form";
import { REQUIRED } from "../../shared/constant/validation";

const PAGE_TITLE = "Rincian Turnamen";

const TournamentDetailPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const match = useRouteMatch<{ id: string }>();

  // TOURNAMENT
  const isAuth = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectAccountUser);
  const tournament = useAppSelector(selectTournament);
  const participatedItem = useAppSelector(selectParticipateItem);
  const tournamentStatus = useAppSelector(selectTournamentsStatus);
  const tournamentError = useAppSelector(selectTournamentsError);

  useEffect(() => {
    if (!isLoaded && tournamentStatus.list !== "idle")
      dispatch(resetTournament());
  }, []);

  useEffect(() => {
    if (tournamentStatus.item === "idle" && !isLoaded) {
      const { id } = match.params;
      dispatch(getTournament(Number(id)));
      setIsLoaded(true);
    } else if (tournamentStatus.item === "success") {
      // console.log(informationStatus);
    } else if (tournamentStatus.item === "failed") {
      // console.log(informationError);
    }
  }, [tournamentStatus.item]);

  const [isParticipateLoaded, setIsParticipateLoaded] = useState(false);

  useEffect(() => {
    if (!isParticipateLoaded && tournamentStatus.participate_item !== "idle")
      dispatch(resetTournament());
  }, []);

  useEffect(() => {
    if (tournamentStatus.participate_item === "idle" && !isParticipateLoaded) {
      const { id } = match.params;
      dispatch(getParticipate(Number(id)));
      setIsParticipateLoaded(true);
    } else if (tournamentStatus.participate_item === "success") {
      // console.log(informationStatus);
    } else if (tournamentStatus.participate_item === "failed") {
      // console.log(informationError);
    }
  }, [tournamentStatus.participate_item]);

  const { id } = match.params;

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ParticipatePayload>({
    defaultValues: {
      _method: "POST",
      tournament_id: Number(id),
      kehadiran: participatedItem?.kehadiran ? "true" : "false",
      feedback: participatedItem?.feedback ?? "",
    },
  });

  const onSubmit = (data: any) => {
    dispatch(postParticipate({ ...data, kehadiran: data.kehadiran === "true" }));
  };

  useEffect(() => {
    if (tournamentStatus.participate_post === "success") {
      presentToast("Konfirmasi kedatangan berhasil disimpan.");
      // dispatch(resetOrder());
    } else if (tournamentStatus.participate_post === "failed") {
      if (typeof tournamentError.participate_post == "string") {
        presentToast(tournamentError.participate_post, "danger");
      } else if (typeof tournamentError.participate_post == "object") {
        // if validation error
        for (let key in tournamentError.participate_post) {
          presentToast(tournamentError.participate_post[key][0], "danger");
        }
      } else {
        presentToast("Konfirmasi kedatangan gagal disimpan", "danger");
      }
      // dispatch(resetOrder());
    }
  }, [tournamentError.participate_post, tournamentStatus.participate_post]);

  useEffect(() => {
    setValue("kehadiran", participatedItem?.kehadiran ? "true" : "false");
    setValue("feedback", participatedItem?.feedback ?? "");
  }, [participatedItem]);

  return (
    <IonPage className="tournament-detail">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="img-container">
          <img
            src={
              tournament?.banner_img
                ? `${TOURNAMENT_IMG_URL}/${tournament?.banner_img}`
                : placeholder
            }
            alt="img"
          />
        </div>

        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={async () => {
              await Browser.open({
                url: `https://www.google.com/maps/search/?api=1&query=${tournament?.coordinate}`,
              });
            }}
          >
            <IonIcon icon={mapOutline} />
          </IonFabButton>
        </IonFab>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-lg font-bold" no-padding>
                {tournament?.name ?? "-"}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {tournament?.is_public ? (
                  <IonChip className="text-xs" color="success">
                    Untuk Publik
                  </IonChip>
                ) : (
                  <IonChip className="text-xs" color="danger">
                    Hanya Anggota
                  </IonChip>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Tgl Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="line-break text-sm font-bold" no-padding>
                {tournament?.from ? formatDate(tournament?.from) : ""} s/d{" "}
                {tournament?.to ? formatDate(tournament?.to) : "Selesai"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Lokasi Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {tournament?.location ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Contact Person
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {tournament?.contact_person ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Deskripsi Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {tournament?.description ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        {(isAuth && participatedItem) && (
          <div className="flex flex-col items-center justify-center mt-4">
            <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="feedback"
                type="text"
                label="Feedback"
                control={control}
                rules={{ required: REQUIRED }}
                errors={errors}
              />

              <Radio
                name="kehadiran"
                label="Kehadiran"
                radioItems={[
                  { label: "Hadir", value: "true" },
                  { label: "Tidak Hadir", value: "false" },
                ]}
                control={control}
                rules={{ required: REQUIRED }}
                errors={errors}
              />

              <IonButton
                color={"primary"}
                expand="block"
                type="submit"
                className="mt-10"
                disabled={tournamentStatus.participate_post === "loading"}
              >
                Berikan Feedback
              </IonButton>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TournamentDetailPage;
