import "./attended-event.css";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import calendar from "../../assets/icon8/calendar.png";
import marker from "../../assets/icon8/marker.png";
import call from "../../assets/icon8/call.png";

import {
  getAttendedEvents,
  resetEvent,
  selectAttendedEvent,
  // selectEventsError,
  selectEventsStatus,
} from "../../redux/features/events/eventsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { EVENT } from "../../shared/constant/routes";
import { EVENT_IMG_URL } from "../../shared/constant/setting";
import { formatDate } from "../../shared/utils";

const PAGE_TITLE = "Kegiatan Dihadiri";

const AttendedEventPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  // Home
  const attended = useAppSelector(selectAttendedEvent);
  const eventsStatus = useAppSelector(selectEventsStatus);
  // const eventsError = useAppSelector(selectEventsError);

  useEffect(() => {
    if (!isLoaded && eventsStatus.attended !== "idle") dispatch(resetEvent());
  }, []);

  useEffect(() => {
    if (eventsStatus.attended === "idle") {
      dispatch(getAttendedEvents());
      setIsLoaded(true);
    } else if (eventsStatus.attended === "success") {
      // console.log(homeStatus);
    } else if (eventsStatus.attended === "failed") {
      // console.log(homeError);
    }
  }, [eventsStatus]);

  return (
    <IonPage className="attended-event">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="grey" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="mx-2">
          {eventsStatus.list === "success" &&
            attended.length > 0 &&
            attended.map((item, index) => {
              return (
                <IonCard
                  className="my-4 rounded-2xl"
                  key={index}
                  onClick={() => {
                    history.push(`${EVENT}/${item.event.id}`);
                  }}
                >
                  <div className="img-container">
                    <img
                      alt=" "
                      src={`${EVENT_IMG_URL}/${item.event.banner_img}`}
                    />
                  </div>

                  <IonCardHeader>
                    <IonCardTitle>{item.event.name}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={calendar} />
                        </IonCol>
                        <IonCol>
                          {formatDate(item.event.from)} s/d{" "}
                          {item.event.to
                            ? formatDate(item.event.to)
                            : "Selesai"}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={marker} />
                        </IonCol>
                        <IonCol>{item.event.location}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={call} />
                        </IonCol>
                        <IonCol>{item.event.contact_person}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AttendedEventPage;
