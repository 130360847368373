import { getToken } from "../shared/utils";

export async function client(endpoint: string, param: any) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + getToken(),
  };

  const config = {
    method: param.body ? "POST" : "GET",
    ...param.customConfig,
    headers: {
      ...headers,
      ...param.customConfig?.headers,
    },
  };

  if (param.body) {
    config.body = JSON.stringify(param.body);
  }

  let data;
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${baseUrl}/api/${endpoint}`, config);
    data = await response.json();
    // if success or failed validation
    if (response.ok || response.status === 400 || response.status === 422) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }
    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint: string, customConfig = {}) {
  return client(endpoint, { ...customConfig });
};

client.post = function (endpoint: string, body: any, customConfig = {}) {
  return client(endpoint, { ...customConfig, body });
};
