import "./register.css";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { IonButton, IonContent, IonHeader, IonPage } from "@ionic/react";
import { Camera, CameraResultType } from '@capacitor/camera';

import pbsi from "../../assets/img/pbsi.webp";
import wave from "../../assets/img/wave.svg";
import { Input } from "../../components/Form";
import {
  getProfile,
  register,
  resetAccount,
  selectAccountError,
  selectAccountRole,
  selectAccountStatus,
  selectIsAuthenticated,
} from "../../redux/features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { HOME, LOGIN, TABS } from "../../shared/constant/routes";
import { REQUIRED } from "../../shared/constant/validation";
import useShowToast from "../../shared/hooks/useShowToast";
import { RegisterPayload } from "../../shared/interfaces";

const RegisterPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const history = useHistory();
  const [nationalityCard, setNationalityCard] = useState<string | null>(null);
  const {
    handleSubmit, control,
    setValue,
    // register,
    // getValues,
    formState: { errors },
  } = useForm<RegisterPayload>({
    defaultValues: {
      name: "anggota baru",
      username: "anggotabaru",
      password: "password",
      password_confirmation: "password",
      phone: "085265657878",
      email: "anggotabaru@gmail.com",
      // nationality_card: "",
    },
  });

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
    });

    if (image.dataUrl) {
      setNationalityCard(image.dataUrl);
      setValue("nationality_card", image.dataUrl)
    }
  };

  const onSubmit = (data: any) => {
    // console.log(data);
    dispatch(register(data));
  };

  const isAuth = useAppSelector(selectIsAuthenticated);
  const role = useAppSelector(selectAccountRole);
  const accountStatus = useAppSelector(selectAccountStatus);
  const accountError = useAppSelector(selectAccountError);

  useEffect(() => {
    if (accountStatus.register === "success") {
      presentToast("Pendaftaran berhasil");
      dispatch(resetAccount());
      history.push(LOGIN);
    } else if (accountStatus.register === "failed") {
      // console.log(accountError.login);
      presentToast("Pendaftaran gagal, " + accountError.register, "danger");
      dispatch(resetAccount());
    }
  }, [accountError.register, accountStatus.register, presentToast]);

  return (
    <IonPage>
      <IonHeader translucent> </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense"> </IonHeader>

        <div
          style={{ backgroundImage: `url(${pbsi})` }}
          className="bg-center bg-no-repeat"
        >
          <div
            style={{ backgroundImage: `url(${wave})` }}
            className="bg-bottom bg-no-repeat"
          >
            <div className="flex flex-col items-center justify-center mx-4">
              <p className="font-bold text-2xl text-center mb-4">
                Persatuan Bulu Tangkis Pekanbaru
              </p>

              <p className="text-center mb-4">
                Silahkan Isi Formulir Pendaftaran Anggota.
              </p>

              <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
                <Input
                  name="name"
                  label="Nama"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="username"
                  label="Username Login"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="password"
                  type="password"
                  label="Password"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="password_confirmation"
                  type="password"
                  label="Konfirmasi Password"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="phone"
                  label="No Handphone"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="email"
                  label="Email"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                {nationalityCard && <>
                  <p className="text-center mt-4 mb-4">
                    Foto KTP Dipilih.
                  </p>
                  <div>
                    <img src={nationalityCard} alt="img" />
                  </div>
                </>}

                <IonButton
                  color={"secondary"}
                  expand="block"
                  type="button"
                  className="mt-4"
                  onClick={
                    async (e) => {
                      await takePicture()
                    }
                  }
                >
                  Pilih Foto KTP
                </IonButton>

                <IonButton
                  color={"primary"}
                  expand="block"
                  type="submit"
                  className="mt-4 mb-4"
                  disabled={accountStatus.login === "loading"}
                >
                  Daftar Sekarang
                </IonButton>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPage;
