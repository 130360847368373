import "./order-detail.css";

import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { Browser } from "@capacitor/browser";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRow,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  getOrder,
  resetOrder,
  selectOrder,
  selectOrdersError,
  selectOrdersStatus,
  updateOrder,
} from "../../redux/features/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatNumber } from "../../shared/utils";
import { ATTRIBUTE_IMG_URL } from "../../shared/constant/setting";
import useShowToast from "../../shared/hooks/useShowToast";
import { ORDER, TABS } from "../../shared/constant/routes";

const PAGE_TITLE = "Rincian Pemesanan";

const OrderDetailPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [presentToast] = useShowToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const match = useRouteMatch<{ id: string }>();

  // ORDER
  const order = useAppSelector(selectOrder);
  const orderStatus = useAppSelector(selectOrdersStatus);
  const orderError = useAppSelector(selectOrdersError);

  useEffect(() => {
    if (!isLoaded && orderStatus.item !== "idle") dispatch(resetOrder());
  }, []);

  useEffect(() => {
    if (orderStatus.item === "idle" && !isLoaded) {
      const { id } = match.params;
      dispatch(getOrder(Number(id)));
      setIsLoaded(true);
    } else if (orderStatus.item === "success") {
      // console.log(informationStatus);
    } else if (orderStatus.item === "failed") {
      // console.log(informationError);
    }
  }, [orderStatus.item]);

  const orderStatusBadge = (status: string) => {
    if (status === "1") {
      return (
        <IonBadge className="text-xs" color="danger">
          Menunggu Pembayaran
        </IonBadge>
      );
    } else if (status === "2") {
      return (
        <IonBadge className="text-xs" color="warning">
          Menunggu Diproses
        </IonBadge>
      );
    } else if (status === "3") {
      return (
        <IonBadge className="text-xs" color="success">
          Diproses
        </IonBadge>
      );
    } else if (status === "4") {
      return (
        <IonBadge className="text-xs" color="success">
          Dikirim
        </IonBadge>
      );
    } else if (status === "5") {
      return (
        <IonBadge className="text-xs" color="success">
          Selesai
        </IonBadge>
      );
    }
  };

  useEffect(() => {
    if (orderStatus.update === "success") {
      presentToast("Konfirmasi penerimaan barang berhasil");
      history.replace(`${TABS}${ORDER}`);
      history.push(`${TABS}${ORDER}`);
    } else if (orderStatus.update === "failed") {
      if (typeof orderError.update == "string") {
        presentToast(orderError.update, "danger");
      } else if (typeof orderError.update == "object") {
        // if validation error
        for (let key in orderError.update) {
          presentToast(orderError.update[key][0], "danger");
        }
      } else {
        presentToast("Konfirmasi penerimaan barang gagal", "danger");
      }
      dispatch(resetOrder());
    }
    setIsSubmiting(false);
  }, [orderError.update, orderStatus.update, presentToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItemGroup>
            <IonItem>
              <IonLabel>
                <div className="text-xs">No Invoice</div>
                <div className="text-md font-bold">
                  <b>#{order?.invoice_no}</b>
                </div>
              </IonLabel>
            </IonItem>

            <IonItemDivider color="light">
              <IonLabel className="font-bold">Detail Produk</IonLabel>
            </IonItemDivider>
            {order?.order_details &&
              order?.order_details?.length > 0 &&
              order.order_details.map((item, i) => (
                <IonItem key={i}>
                  <IonThumbnail slot="start">
                    <img
                      alt={item.member_attribute.name}
                      src={`${ATTRIBUTE_IMG_URL}/${item.member_attribute.image}`}
                    />
                  </IonThumbnail>
                  <IonGrid className="p-0">
                    <IonRow>
                      <IonCol className="p-0">
                        {item.member_attribute.name}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="p-0 text-sm">
                        {item.quantity} x Rp {formatNumber(item.price)}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="p-0 font-bold">
                        Total Harga Rp {formatNumber(item.subtotal)}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              ))}
            <IonItem>
              <IonLabel className="text-lg">
                Total Belanja{" "}
                <b>Rp {formatNumber(order?.total_transaction ?? 0)}</b>
              </IonLabel>
            </IonItem>

            <IonItemDivider color="light">
              <IonLabel className="font-bold">Info Pembeli</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>
                <div className="text-md">
                  Nama: <b>{order?.member?.name ?? "-"}</b>
                </div>
                <div className="text-sm">
                  No HP: <b>{order?.member?.phone ?? "-"}</b>
                </div>
                <div className="text-sm">
                  Alamat: <b>{order?.member?.address ?? "-"}</b>
                </div>
              </IonLabel>
            </IonItem>

            {(order?.status === "4" || order?.status === "5") && (
              <>
                <IonItemDivider color="light">
                  <IonLabel>Info Pengiriman</IonLabel>
                </IonItemDivider>
                <IonItem>
                  <IonLabel>
                    <div className="text-md">
                      Kurir: <b>{order?.shipment_company ?? "-"}</b>
                    </div>
                    <div className="text-sm">
                      No Resi: {order?.shipment_no ?? "-"}
                    </div>
                  </IonLabel>
                </IonItem>
              </>
            )}

            <IonItem>
              <IonLabel>
                <div className="text-xs">Status Transaksi</div>
                <div className="text-md font-bold">
                  {orderStatusBadge(order?.status.toString() ?? "")}
                </div>
              </IonLabel>
            </IonItem>
          </IonItemGroup>
        </IonList>
      </IonContent>
      <IonFooter>
        {/* status 0 = btn bayar */}
        {order?.status === "1" && (
          <IonButton
            color={"primary"}
            expand="block"
            type="button"
            onClick={async () =>
              await Browser.open({
                url: order?.latest_payment?.payment_url ?? "",
              })
            }
          >
            Lakukan Pembayaran
          </IonButton>
        )}
        {/* status 1 atau 2 = btn e tiket */}
        {order?.status === "4" && (
          <IonButton
            disabled={isSubmiting}
            color={"success"}
            expand="block"
            type="button"
            onClick={() => {
              setIsSubmiting(true);
              dispatch(updateOrder(order.id));
            }}
          >
            Konfirmasi Terima
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default OrderDetailPage;
