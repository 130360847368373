import "./edit-profile.css";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { Input } from "../../components/Form";
import {
  resetAccount,
  selectAccountError,
  selectAccountStatus,
  selectAccountUser,
  updateProfile,
} from "../../redux/features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PROFILE, TABS } from "../../shared/constant/routes";
import { USER_IMG_URL } from "../../shared/constant/setting";
import { REQUIRED } from "../../shared/constant/validation";
import { usePhotoGallery } from "../../shared/hooks/usePhotoGallery";
import useShowToast from "../../shared/hooks/useShowToast";
import { UserPayload } from "../../shared/interfaces";
import { formatDate } from "../../shared/utils";

const PAGE_TITLE = "Ubah Profil";

const EditProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const history = useHistory();
  const { photo, takePhoto } = usePhotoGallery();

  const user = useAppSelector(selectAccountUser);
  const accountStatus = useAppSelector(selectAccountStatus);
  const accountError = useAppSelector(selectAccountError);

  const {
    handleSubmit,
    control,
    setValue,
    // register,
    getValues,
    formState: { errors },
  } = useForm<UserPayload>({
    defaultValues: {
      name: user?.member?.name,
      username: user?.username,
      phone: user?.member?.phone,
      email: user?.member?.email,
      address: user?.member?.address,
      place_of_birth: user?.member?.place_of_birth,
      date_of_birth: user?.member?.date_of_birth
        ? formatDate(user?.member?.date_of_birth, "dd-MM-Y")
        : "",
      nationality_id: user?.member?.nationality_id,
    },
  });

  useEffect(() => {
    setValue("photo", photo);
  }, [photo]);

  const onSubmit = (data: any) => {
    if (getValues("password") && !getValues("password_confirmation")) {
      presentToast("Konfirmasi password harus diisi", "danger");
      return;
    }

    dispatch(updateProfile(data));
  };

  useEffect(() => {
    if (accountStatus.updateProfile === "success") {
      presentToast("Profil berhasil disimpan.");
      history.push(`${TABS}${PROFILE}`);
      dispatch(resetAccount());
    } else if (accountStatus.updateProfile === "failed") {
      presentToast("Ubah profil gagal", "danger");
      // if validation error
      if (
        accountError.updateProfile &&
        typeof accountError.updateProfile == "object"
      ) {
        for (let key in accountError.updateProfile) {
          presentToast(accountError.updateProfile[key][0], "danger");
        }
      }
      dispatch(resetAccount());
    }
  }, [accountError.updateProfile, accountStatus.updateProfile, presentToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="flex flex-col items-center justify-center mx-2 mt-4">
          <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="name"
              label="Nama"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="username"
              label="Username"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="phone"
              type="number"
              label="No Telepon/Hp"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="email"
              type="email"
              label="Email"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="address"
              label="Alamat"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="place_of_birth"
              label="Tempat Lahir"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="date_of_birth"
              label="Tanggal Lahir"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="nationality_id"
              label="No KTP"
              control={control}
              rules={{ required: REQUIRED }}
              errors={errors}
            />

            <Input
              name="password"
              type="password"
              label="Password"
              control={control}
              errors={errors}
            />

            <Input
              name="password_confirmation"
              type="password"
              label="Konfirmasi Password"
              control={control}
              rules={{ required: getValues("password") ? REQUIRED : undefined }}
              errors={errors}
            />

            <IonItem no-padding>
              <IonGrid>
                <IonRow>
                  <IonCol no-padding>Foto Saat Ini</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol no-padding>
                    <img
                      src={
                        photo
                          ? photo
                          : `${USER_IMG_URL}/${
                              user?.profile_pic ?? "default.png"
                            }`
                      }
                      alt={user?.name}
                    />
                  </IonCol>
                </IonRow>

                <IonButton
                  color={"secondary"}
                  expand="block"
                  type="button"
                  onClick={() => takePhoto()}
                >
                  Pilih Foto
                </IonButton>
              </IonGrid>
            </IonItem>

            <IonButton
              color={"primary"}
              expand="block"
              type="submit"
              className="mt-10"
              disabled={accountStatus.updateProfile === "loading"}
            >
              Ubah Profil
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfilePage;
