import "./member-card.css";

import { useEffect, useState } from "react";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import placeholder from "../../assets/img/placeholder.png";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
// import { E_TICKET } from "../../shared/constant/routes";
import {
  selectAccountUser,
  selectAccountStatus,
  resetAccount,
  getProfile,
} from "../../redux/features/account/accountSlice";
import { MEMBER_CARD_IMG_URL } from "../../shared/constant/setting";

const PAGE_TITLE = "Kartu Member";

const MemberCardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const user = useAppSelector(selectAccountUser);
  const accountStatus = useAppSelector(selectAccountStatus);
  // const accountError = useAppSelector(selectAccountError);

  useEffect(() => {
    if (!isLoaded && accountStatus.profile !== "idle") dispatch(resetAccount());
  }, []);

  useEffect(() => {
    if (accountStatus.profile === "idle" && !isLoaded) {
      dispatch(getProfile());
      setIsLoaded(true);
    }
  }, [accountStatus.profile]);

  useEffect(() => {
    if (accountStatus.profile === "success") {
      // console.log(user);
    } else if (accountStatus.profile === "failed") {
      // console.log(accountError.profile);
    }
  }, [accountStatus.profile]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="img-container">
          <img
            src={
              user?.member?.member_card
                ? `${MEMBER_CARD_IMG_URL}/${user?.member?.member_card}`
                : placeholder
            }
            alt="img"
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MemberCardPage;
