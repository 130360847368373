import { useIonToast } from "@ionic/react";

const useShowToast = () => {
  const [present] = useIonToast();

  const presentToast = (
    message: string,
    color: "primary" | "success" | "warning" | "danger" = "success",
    position: "top" | "middle" | "bottom" = "bottom"
  ) => {
    present({
      message,
      color,
      duration: 1500,
      position,
    });
  };

  return [presentToast];
};

export default useShowToast;
