import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import accountReducer from "./features/account/accountSlice";
import eventReducer from "./features/events/eventsSlice";
import tournamentsReducer from "./features/tournaments/tournamentsSlice";
import attributesReducer from "./features/attributes/attributesSlice";
import ordersReducer from "./features/orders/ordersSlice";
import cartReducer from "./features/cart/cartSlice";
import paymentReducer from "./features/payment/paymentSlice";
import communityReducer from "./features/community/communitySlice";

export const makeStore = () => {
  return configureStore({
    reducer: {
      account: accountReducer,
      events: eventReducer,
      tournaments: tournamentsReducer,
      attributes: attributesReducer,
      orders: ordersReducer,
      cart: cartReducer,
      payment: paymentReducer,
      community: communityReducer,
    },
  });
};

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
