import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import { ParticipatePayload, Tournament, TournamentParticipant, ValidationError } from "../../../shared/interfaces";
import { AppState } from "../../store";

export interface TournamentState {
  list: Tournament[];
  item: Tournament | null;
  participate: TournamentParticipant[];
  participate_item: TournamentParticipant | null;
  status: {
    list: string;
    item: string;
    participate: string;
    participate_item: string;
    participate_post: string;
    participate_update: string;
  };
  error: {
    list: string | null;
    item: string | null;
    participate: string | null;
    participate_item: string | null;
    participate_post: string | ValidationError | null;
    participate_update: string | ValidationError | null;
  };
}

const initialState: TournamentState = {
  list: [],
  item: null,
  participate: [],
  participate_item: null,
  status: { list: "idle", item: "idle", participate: "idle", participate_item: "idle", participate_post: "idle", participate_update: "idle" },
  error: { list: null, item: null, participate: null, participate_item: null, participate_post: null, participate_update: null },
};

export const getTournaments = createAsyncThunk(
  "tournaments/getTournaments",
  async () => {
    const response = (await client.get("tournament")).data;
    return response.data.tournament;
  }
);

export const getTournament = createAsyncThunk(
  "tournaments/getTournament",
  async (id: number) => {
    const response = (await client.get(`tournament/${id}`)).data;
    return response.data.tournament;
  }
);

export const getParticipates = createAsyncThunk(
  "tournaments/getParticipates",
  async () => {
    const response = (await client.get("participate")).data;
    return response.data.participate;
  }
);

export const getParticipate = createAsyncThunk(
  "tournaments/getParticipate",
  async (id: number) => {
    const response = (await client.get(`participate/${id}`)).data;
    return response.data.participate;
  }
);

export const postParticipate = createAsyncThunk(
  "tournaments/postParticipate",
  async (payload: ParticipatePayload) => {
    const response = (await client.post("participate", payload)).data;
    return response;
  }
);

export const updateParticipate = createAsyncThunk(
  "tournaments/updateParticipate",
  async (payload: ParticipatePayload) => {
    const response = (
      await client.post(`participate/${payload.participate_id ?? ""}`, payload)
    ).data;
    return response;
  }
);

const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {
    resetTournament(state) {
      state.list = [];
      state.item = null;
      state.status = {
        list: "idle",
        item: "idle",
        participate: "idle",
        participate_item: "idle",
        participate_post: "idle",
        participate_update: "idle"
      };
      state.error = {
        list: null,
        item: null,
        participate: null,
        participate_item: null,
        participate_post: null,
        participate_update: null
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTournaments.pending, (state, action) => {
        state.status.list = "loading";
      })
      .addCase(getTournaments.rejected, (state, action) => {
        state.status.list = "failed";
        state.error.list = action.error.message ?? null;
      })
      .addCase(getTournaments.fulfilled, (state, action) => {
        state.status.list = "success";
        state.list = action.payload;
      })

      .addCase(getTournament.pending, (state, action) => {
        state.status.item = "loading";
      })
      .addCase(getTournament.rejected, (state, action) => {
        state.status.item = "failed";
        state.error.item = action.error.message ?? null;
      })
      .addCase(getTournament.fulfilled, (state, action) => {
        state.status.item = "success";
        state.item = action.payload;
      })

      .addCase(getParticipates.pending, (state, action) => {
        state.status.participate = "loading";
      })
      .addCase(getParticipates.rejected, (state, action) => {
        state.status.participate = "failed";
        state.error.participate = action.error.message ?? null;
      })
      .addCase(getParticipates.fulfilled, (state, action) => {
        state.status.participate = "success";
        state.participate = action.payload;
      })

      .addCase(getParticipate.pending, (state, action) => {
        state.status.participate_item = "loading";
      })
      .addCase(getParticipate.rejected, (state, action) => {
        state.status.participate_item = "failed";
        state.error.participate_item = action.error.message ?? null;
      })
      .addCase(getParticipate.fulfilled, (state, action) => {
        state.status.participate_item = "success";
        state.participate_item = action.payload;
      })

      .addCase(postParticipate.pending, (state, action) => {
        state.status.participate_post = "loading";
      })
      .addCase(postParticipate.rejected, (state, action) => {
        state.status.participate_post = "failed";
        state.error.participate_post = action.error.message ?? null;
      })
      .addCase(postParticipate.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.participate_post = "success";
          state.participate_item = response.data.participate;
        } else {
          state.status.participate_post = "failed";
          state.error.participate_post =
            response.errors ?? response.message ?? null;
        }
      })

      .addCase(updateParticipate.pending, (state, action) => {
        state.status.participate_update = "loading";
      })
      .addCase(updateParticipate.rejected, (state, action) => {
        state.status.participate_update = "failed";
        state.error.participate_update = action.error.message ?? null;
      })
      .addCase(updateParticipate.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.participate_update = "success";
          state.participate_item = response.data.participate;
        } else {
          state.status.participate_update = "failed";
          state.error.participate_update =
            response.errors ?? response.message ?? null;
        }
      });
  },
});

export const selectTournaments = (state: AppState) => state.tournaments.list;
export const selectTournament = (state: AppState) => state.tournaments.item;
export const selectParticipate = (state: AppState) => state.tournaments.participate;
export const selectParticipateItem = (state: AppState) => state.tournaments.participate_item;
export const selectTournamentsStatus = (state: AppState) =>
  state.tournaments.status;
export const selectTournamentsError = (state: AppState) =>
  state.tournaments.error;

export const { resetTournament } = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
