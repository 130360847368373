import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import {
  Cart,
  CartPayload,
  ValidationError,
} from "../../../shared/interfaces/index";
import { AppState } from "../../store";

export interface CartState {
  list: Cart[];
  status: {
    list: string;
    post: string;
    update: string;
    delete: string;
  };
  error: {
    list: string | null;
    post: string | ValidationError | null;
    update: string | ValidationError | null;
    delete: string | ValidationError | null;
  };
}

const initialState: CartState = {
  list: [],
  status: {
    list: "idle",
    post: "idle",
    update: "idle",
    delete: "idle",
  },
  error: { list: null, post: null, update: null, delete: null },
};

export const getCart = createAsyncThunk("cart/getCart", async () => {
  const response = (await client.get("cart")).data;
  return response.data.cart;
});

export const postCart = createAsyncThunk(
  "cart/postCart",
  async (payload: CartPayload) => {
    const response = (await client.post("cart", payload)).data;
    return response;
  }
);

export const updateCart = createAsyncThunk(
  "cart/updateCart",
  async (payload: CartPayload) => {
    const response = (
      await client.post(`cart/${payload.cart_id ?? ""}`, payload)
    ).data;
    return response;
  }
);

export const deleteCart = createAsyncThunk(
  "cart/deleteCart",
  async (id: number) => {
    const response = (await client.post(`cart/${id}`, { _method: "DELETE" }))
      .data;
    return response;
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart(state) {
      state.status = {
        list: "idle",
        post: "idle",
        update: "idle",
        delete: "idle",
      };
      state.error = {
        list: null,
        post: null,
        update: null,
        delete: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCart.pending, (state, action) => {
        state.status.list = "loading";
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status.list = "failed";
        state.error.list = action.error.message ?? null;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.status.list = "success";
        state.list = action.payload;
      })

      .addCase(postCart.pending, (state, action) => {
        state.status.post = "loading";
      })
      .addCase(postCart.rejected, (state, action) => {
        state.status.post = "failed";
        state.error.post = action.error.message ?? null;
      })
      .addCase(postCart.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.post = "success";
          state.list = response.data.cart;
        } else {
          state.status.post = "failed";
          state.error.post = response.errors ?? response.message ?? null;
        }
      })

      .addCase(updateCart.pending, (state, action) => {
        state.status.update = "loading";
      })
      .addCase(updateCart.rejected, (state, action) => {
        state.status.update = "failed";
        state.error.update = action.error.message ?? null;
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.update = "success";
          state.list = response.data.cart;
        } else {
          state.status.update = "failed";
          state.error.update = response.errors ?? response.message ?? null;
        }
      })

      .addCase(deleteCart.pending, (state, action) => {
        state.status.delete = "loading";
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.status.delete = "failed";
        state.error.delete = action.error.message ?? null;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.delete = "success";
          state.list = response.data.cart;
        } else {
          state.status.delete = "failed";
          state.error.delete = response.errors ?? response.message ?? null;
        }
      });
  },
});

export const selectCart = (state: AppState) => state.cart.list;
export const selectCartStatus = (state: AppState) => state.cart.status;
export const selectCartError = (state: AppState) => state.cart.error;

export const { resetCart } = cartSlice.actions;

export default cartSlice.reducer;
