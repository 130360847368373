export const LOGIN = "/login";
export const REGISTER = "/register";
export const TABS = "/tabs";
export const HOME = "/home";
export const EVENT = "/event";
export const COMMUNITY = "/community";
export const ATTENDED_EVENT = "/attended-event";
export const TOURNAMENT = "/tournament";
export const PARTICIPATED_TOURNAMENT = "/participated-tournament";
export const ATTRIBUTE = "/member-attribute";
export const ORDER = "/order";
export const CART = "/cart";
export const PROFILE = "/profile";
export const MEMBER_CARD = "/member-card";
