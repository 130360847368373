import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useState } from "react";

export function usePhotoGallery() {
  const [photo, setPhoto] = useState<string>();

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
      quality: 75,
      width: 200,
      height: 200,
    });
    setPhoto("data:image/jpg;base64," + photo.base64String);
  };

  return {
    photo,
    takePhoto,
  };
}
