import "./attribute.css";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

import { useEffect, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  getAttributes,
  resetAttribute,
  selectAttributes,
  // selectAttributesError,
  selectAttributesStatus,
} from "../../redux/features/attributes/attributesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ATTRIBUTE, CART } from "../../shared/constant/routes";
import { ATTRIBUTE_IMG_URL } from "../../shared/constant/setting";
import { formatNumber } from "../../shared/utils";
import { Link, useHistory } from "react-router-dom";
import { cartOutline } from "ionicons/icons";

const PAGE_TITLE = "Attribut Anggota";

const AttributePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  // Home
  const attributes = useAppSelector(selectAttributes);
  const attributesStatus = useAppSelector(selectAttributesStatus);
  // const attributesError = useAppSelector(selectAttributesError);

  useEffect(() => {
    if (!isLoaded && attributesStatus.list !== "idle")
      dispatch(resetAttribute());
  }, []);

  useEffect(() => {
    if (attributesStatus.list === "idle") {
      dispatch(getAttributes());
      setIsLoaded(true);
    } else if (attributesStatus.list === "success") {
      // console.log(homeStatus);
    } else if (attributesStatus.list === "failed") {
      // console.log(homeError);
    }
  }, [attributesStatus]);

  return (
    <IonPage className="attribute">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                history.push(CART);
              }}
            >
              <IonIcon icon={cartOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="grey" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="mx-2">
          <IonGrid>
            <IonRow>
              {attributesStatus.list === "success" &&
                attributes.length > 0 &&
                attributes.map((item, index) => {
                  return (
                    <IonCol key={index} size="6">
                      <Link to={`${ATTRIBUTE}/${item.id}`}>
                        <IonCard className="my-4 rounded-2xl" key={index}>
                          <div className="img-container">
                            <img
                              alt=" "
                              src={`${ATTRIBUTE_IMG_URL}/${item.image}`}
                            />
                          </div>

                          <IonCardHeader className="px-2 py-1">
                            <IonCardTitle className="text-sm">
                              {item.name}
                            </IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent className="px-2 py-1">
                            Rp {formatNumber(item.price)}
                          </IonCardContent>
                        </IonCard>
                      </Link>
                    </IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AttributePage;
