import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import { MemberAttribute } from "../../../shared/interfaces";
import { AppState } from "../../store";

export interface AttributeState {
  list: MemberAttribute[];
  item: MemberAttribute | null;
  status: {
    list: string;
    item: string;
  };
  error: {
    list: string | null;
    item: string | null;
  };
}

const initialState: AttributeState = {
  list: [],
  item: null,
  status: { list: "idle", item: "idle" },
  error: { list: null, item: null },
};

export const getAttributes = createAsyncThunk(
  "attributes/getAttributes",
  async () => {
    const response = (await client.get("attribute")).data;
    return response.data.attribute;
  }
);

export const getAttribute = createAsyncThunk(
  "attributes/getAttribute",
  async (id: number) => {
    const response = (await client.get(`attribute/${id}`)).data;
    return response.data.attribute;
  }
);

const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    resetAttribute(state) {
      state.list = [];
      state.item = null;
      state.status = {
        list: "idle",
        item: "idle",
      };
      state.error = {
        list: null,
        item: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAttributes.pending, (state, action) => {
        state.status.list = "loading";
      })
      .addCase(getAttributes.rejected, (state, action) => {
        state.status.list = "failed";
        state.error.list = action.error.message ?? null;
      })
      .addCase(getAttributes.fulfilled, (state, action) => {
        state.status.list = "success";
        state.list = action.payload;
      })

      .addCase(getAttribute.pending, (state, action) => {
        state.status.item = "loading";
      })
      .addCase(getAttribute.rejected, (state, action) => {
        state.status.item = "failed";
        state.error.item = action.error.message ?? null;
      })
      .addCase(getAttribute.fulfilled, (state, action) => {
        state.status.item = "success";
        state.item = action.payload;
      });
  },
});

export const selectAttributes = (state: AppState) => state.attributes.list;
export const selectAttribute = (state: AppState) => state.attributes.item;
export const selectAttributesStatus = (state: AppState) =>
  state.attributes.status;
export const selectAttributesError = (state: AppState) =>
  state.attributes.error;

export const { resetAttribute } = attributesSlice.actions;

export default attributesSlice.reducer;
