import "./event-detail.css";

import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import { Browser } from "@capacitor/browser";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import placeholder from "../../assets/img/placeholder.png";
import {
  getAttendedItem,
  getEvent,
  postAttended,
  resetEvent,
  selectAttendedItem,
  selectEvent,
  selectEventsError,
  selectEventsStatus,
} from "../../redux/features/events/eventsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatDate } from "../../shared/utils";
import { EVENT_IMG_URL } from "../../shared/constant/setting";
import { mapOutline } from "ionicons/icons";
import useShowToast from "../../shared/hooks/useShowToast";
import { useForm } from "react-hook-form";
import { Input, Radio, Toggle } from "../../components/Form";
import { REQUIRED } from "../../shared/constant/validation";
import { AttendedPayload } from "../../shared/interfaces";
import { selectIsAuthenticated } from "../../redux/features/account/accountSlice";

const PAGE_TITLE = "Rincian Kegiatan";

const EventDetailPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const match = useRouteMatch<{ id: string }>();

  const isAuth = useAppSelector(selectIsAuthenticated);
  const event = useAppSelector(selectEvent);
  const attendedItem = useAppSelector(selectAttendedItem);
  const eventStatus = useAppSelector(selectEventsStatus);
  const eventError = useAppSelector(selectEventsError);

  useEffect(() => {
    if (!isLoaded && eventStatus.item !== "idle") dispatch(resetEvent());
  }, []);

  useEffect(() => {
    if (eventStatus.item === "idle" && !isLoaded) {
      const { id } = match.params;
      dispatch(getEvent(Number(id)));
      setIsLoaded(true);
    } else if (eventStatus.item === "success") {
      // console.log(informationStatus);
    } else if (eventStatus.item === "failed") {
      // console.log(informationError);
    }
  }, [eventStatus.item]);

  const [isAttendanceLoaded, setIsAttendanceLoaded] = useState(false);

  useEffect(() => {
    if (!isAttendanceLoaded && eventStatus.attended_item !== "idle")
      dispatch(resetEvent());
  }, []);

  useEffect(() => {
    if (eventStatus.attended_item === "idle" && !isAttendanceLoaded) {
      const { id } = match.params;
      dispatch(getAttendedItem(Number(id)));
      setIsAttendanceLoaded(true);
    } else if (eventStatus.attended_item === "success") {
      // console.log(informationStatus);
    } else if (eventStatus.attended_item === "failed") {
      // console.log(informationError);
    }
  }, [eventStatus.attended_item]);

  const { id } = match.params;

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AttendedPayload>({
    defaultValues: {
      _method: "POST",
      event_id: Number(id),
      kehadiran: attendedItem?.kehadiran ? "true" : "false",
      feedback: attendedItem?.feedback ?? "",
    },
  });

  const onSubmit = (data: any) => {
    dispatch(postAttended({ ...data, kehadiran: data.kehadiran === "true" }));
  };

  useEffect(() => {
    if (eventStatus.attended_post === "success") {
      presentToast("Konfirmasi kedatangan berhasil disimpan.");
      // dispatch(resetOrder());
    } else if (eventStatus.attended_post === "failed") {
      if (typeof eventError.attended_post == "string") {
        presentToast(eventError.attended_post, "danger");
      } else if (typeof eventError.attended_post == "object") {
        // if validation error
        for (let key in eventError.attended_post) {
          presentToast(eventError.attended_post[key][0], "danger");
        }
      } else {
        presentToast("Konfirmasi kedatangan gagal disimpan", "danger");
      }
      // dispatch(resetOrder());
    }
  }, [eventError.attended_post, eventStatus.attended_post]);

  useEffect(() => {
    setValue("kehadiran", attendedItem?.kehadiran ? "true" : "false");
    setValue("feedback", attendedItem?.feedback ?? "");
  }, [attendedItem]);

  return (
    <IonPage className="event-detail">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="img-container">
          <img
            src={
              event?.banner_img
                ? `${EVENT_IMG_URL}/${event?.banner_img}`
                : placeholder
            }
            alt="img"
          />
        </div>

        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={async () => {
              await Browser.open({
                url: `https://www.google.com/maps/search/?api=1&query=${event?.coordinate}`,
              });
            }}
          >
            <IonIcon icon={mapOutline} />
          </IonFabButton>
        </IonFab>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-lg font-bold" no-padding>
                {event?.name ?? "-"}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                <IonText color="primary">({event?.type_string ?? "-"})</IonText>
                {event?.is_public ? (
                  <IonChip className="text-xs" color="success">
                    Untuk Publik
                  </IonChip>
                ) : (
                  <IonChip className="text-xs" color="danger">
                    Hanya Anggota
                  </IonChip>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Tgl Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="line-break text-sm font-bold" no-padding>
                {event?.from ? formatDate(event?.from) : ""} s/d{" "}
                {event?.to ? formatDate(event?.to) : "Selesai"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Lokasi Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {event?.location ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Contact Person
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {event?.contact_person ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Deskripsi Kegiatan
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {event?.description ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        {isAuth && (
          <div className="flex flex-col items-center justify-center mt-4">
            <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="feedback"
                type="text"
                label="Feedback"
                control={control}
                rules={{ required: REQUIRED }}
                errors={errors}
              />

              <Radio
                name="kehadiran"
                label="Kehadiran"
                radioItems={[
                  { label: "Hadir", value: "true" },
                  { label: "Tidak Hadir", value: "false" },
                ]}
                control={control}
                rules={{ required: REQUIRED }}
                errors={errors}
              />

              <IonButton
                color={"primary"}
                expand="block"
                type="submit"
                className="mt-10"
                disabled={eventStatus.attended_post === "loading"}
              >
                Konfimasi Kehadiran
              </IonButton>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EventDetailPage;
