import "./cart.css";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Browser } from "@capacitor/browser";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonMenuButton,
  IonPage,
  IonRow,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  deleteCart,
  getCart,
  resetCart,
  selectCart,
  // selectCartError,
  selectCartStatus,
  updateCart,
} from "../../redux/features/cart/cartSlice";
import {
  selectOrder,
  selectOrdersStatus,
  selectOrdersError,
  resetOrder,
  postOrder,
} from "../../redux/features/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { HOME, ORDER, TABS } from "../../shared/constant/routes";
import useShowToast from "../../shared/hooks/useShowToast";
import { formatNumber } from "../../shared/utils";
import { add, remove, trash } from "ionicons/icons";
import { ATTRIBUTE_IMG_URL } from "../../shared/constant/setting";

const PAGE_TITLE = "Keranjang Belanja";

const CartPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [presentToast] = useShowToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  // CART LIST
  const cart = useAppSelector(selectCart);
  const cartStatus = useAppSelector(selectCartStatus);
  // const cartError = useAppSelector(selectCartError);

  useEffect(() => {
    if (!isLoaded && cartStatus.list !== "idle") dispatch(resetCart());
  }, []);

  useEffect(() => {
    if (cartStatus.list === "idle" && !isLoaded) {
      dispatch(getCart());
      setIsLoaded(true);
    } else if (cartStatus.list === "success") {
      // console.log(facilitiesStatus.item);
    } else if (cartStatus.list === "failed") {
      // console.log(facilitiesError.item);
    }
  }, [cartStatus.list]);

  const order = useAppSelector(selectOrder);
  const ordersStatus = useAppSelector(selectOrdersStatus);
  const ordersError = useAppSelector(selectOrdersError);

  useEffect(() => {
    const openPaymentGateway = async (url: string) => {
      await Browser.open({
        url: url,
      });
    };

    if (ordersStatus.post === "success") {
      presentToast("Pemesanan attribut berhasil.");
      openPaymentGateway(order?.latest_payment?.payment_url ?? "");
      dispatch(resetOrder());
      history.replace(`${TABS}${HOME}`);
      history.push(`${ORDER}/${order?.id}`);
    } else if (ordersStatus.post === "failed") {
      if (typeof ordersError.post == "string") {
        presentToast(ordersError.post, "danger");
      } else if (typeof ordersError.post == "object") {
        // if validation error
        for (let key in ordersError.post) {
          presentToast(ordersError.post[key][0], "danger");
        }
      } else {
        presentToast("Pemesanan attribut gagal", "danger");
      }
      dispatch(resetOrder());
    }
    setIsSubmiting(false);
  }, [ordersError.post, ordersStatus.post, presentToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          {cart.map((item, i) => (
            <IonItem key={i}>
              <IonThumbnail slot="start">
                <img
                  alt={item.member_attribute.name}
                  src={`${ATTRIBUTE_IMG_URL}/${item.member_attribute.image}`}
                />
              </IonThumbnail>
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol>{item.member_attribute.name}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="font-bold text-sm">
                    Rp {formatNumber(item.member_attribute.price)}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4" className="ion-justify-content-center">
                    <IonButton
                      onClick={() => {
                        dispatch(deleteCart(Number(item.id)));
                      }}
                      color="danger"
                      className="h-9"
                      shape="round"
                      fill="outline"
                      size="small"
                    >
                      <IonIcon slot="icon-only" icon={trash}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="2" className="ion-justify-content-center">
                    <IonButton
                      onClick={() => {
                        dispatch(
                          updateCart({
                            _method: "PUT",
                            cart_id: item.id,
                            quantity: item.quantity - 1,
                          })
                        );
                      }}
                      className="h-9"
                      shape="round"
                      fill="outline"
                      size="small"
                    >
                      <IonIcon slot="icon-only" icon={remove}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="3" className="ion-justify-content-center">
                    <IonItem>
                      <IonInput
                        value={item.quantity}
                        type="number"
                        className="p-1"
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="2" className="ion-justify-content-center">
                    <IonButton
                      onClick={() => {
                        dispatch(
                          updateCart({
                            _method: "PUT",
                            cart_id: item.id,
                            quantity: item.quantity + 1,
                          })
                        );
                      }}
                      className="h-9"
                      shape="round"
                      fill="outline"
                      size="small"
                    >
                      <IonIcon slot="icon-only" icon={add}></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonButton
          disabled={isSubmiting || cart.length <= 0}
          className="m-0"
          color={"success"}
          expand="full"
          type="button"
          onClick={() => {
            setIsSubmiting(true);
            dispatch(postOrder({}));
          }}
        >
          Lanjutkan ke Pembayaran
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default CartPage;
