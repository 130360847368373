import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import {
  LoginPayload,
  RegisterPayload,
  UserPayload,
  ValidationError,
} from "../../../shared/interfaces";
import { User } from "../../../shared/interfaces/index";
import { removeToken, setToken } from "../../../shared/utils";

import type { AppState } from "../../store";
export interface AccountState {
  isAuthenticated: boolean;
  user: User | null;
  role: string | null; //TODO
  status: {
    login: string;
    register: string;
    profile: string;
    updateProfile: string;
  };
  error: {
    login: string | ValidationError | null;
    register: string | ValidationError | null;
    profile: string | null;
    updateProfile: string | ValidationError | null;
  };
}

const initialState: AccountState = {
  isAuthenticated: false,
  user: null,
  role: null,
  status: {
    login: "idle",
    register: "idle",
    profile: "idle",
    updateProfile: "idle",
  },
  error: {
    login: null,
    register: null,
    profile: null,
    updateProfile: null,
  },
};

export const login = createAsyncThunk(
  "account/login",
  async (payload: LoginPayload) => {
    const response = (await client.post("login", payload)).data;
    return response;
  }
);

export const register = createAsyncThunk(
  "account/register",
  async (payload: RegisterPayload) => {
    const response = (await client.post("register", payload)).data;
    return response;
  }
);

export const getProfile = createAsyncThunk(
  "facilities/getProfile",
  async () => {
    const response = (await client.get(`profile`)).data;
    return response;
  }
);

export const updateProfile = createAsyncThunk(
  "account/updateProfile",
  async (payload: UserPayload) => {
    const response = (await client.post("profile", payload)).data;
    return response;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setIsAuthenticated(
      state: { isAuthenticated: boolean },
      action: PayloadAction<boolean>
    ) {
      state.isAuthenticated = action.payload;
    },
    logOut(state) {
      removeToken();
      state.isAuthenticated = false;
      state.user = null;
      state.role = null;
    },
    resetAccount(state) {
      state.status = {
        login: "idle",
        register: "idle",
        profile: "idle",
        updateProfile: "idle",
      };
      state.error = {
        login: null,
        register: null,
        profile: null,
        updateProfile: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.status.login = "loading";
      })
      .addCase(login.rejected, (state, action) => {
        state.status.login = "failed";
        state.error.login = action.error.message ?? null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success && response.data.token) {
          setToken(response.data.token);
          state.user = response.data.user;
          state.role = response.data.roles;
          state.isAuthenticated = true;
          state.status.login = "success";
        } else {
          removeToken();
          state.user = null;
          state.role = null;
          state.isAuthenticated = false;
          state.status.login = "failed";
          state.error.login = response.message ?? null;
        }
      })

      .addCase(register.pending, (state, action) => {
        state.status.register = "loading";
      })
      .addCase(register.rejected, (state, action) => {
        state.status.register = "failed";
        state.error.register = action.error.message ?? null;
      })
      .addCase(register.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success && response.data) {
          state.status.register = "success";
        } else {
          state.status.register = "failed";
          state.error.register = response.message ?? null;
        }
      })

      .addCase(getProfile.pending, (state, action) => {
        state.status.profile = "loading";
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status.profile = "failed";
        state.error.profile = action.error.message ?? null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        const response = action.payload;
        state.user = response.data.user;
        state.role = response.data.roles;
        state.isAuthenticated = true;
        state.status.profile = "success";
      })

      .addCase(updateProfile.pending, (state, action) => {
        state.status.updateProfile = "loading";
      })
      .addCase(updateProfile.rejected, (state, action) => {
        // console.log(action);
        state.status.updateProfile = "failed";
        state.error.updateProfile = action.error.message ?? null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.updateProfile = "success";
        } else {
          state.status.updateProfile = "failed";
          state.error.updateProfile =
            response.errors ?? response.message ?? null;
        }
      });
  },
});

export const selectIsAuthenticated = (state: AppState) =>
  state.account.isAuthenticated;
export const selectAccountUser = (state: AppState) => state.account.user;
export const selectAccountRole = (state: AppState) => state.account.role;
export const selectAccountStatus = (state: AppState) => state.account.status;
export const selectAccountError = (state: AppState) => state.account.error;

export const { setIsAuthenticated, logOut, resetAccount } =
  accountSlice.actions;

export default accountSlice.reducer;
