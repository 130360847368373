import "./tournament.css";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

import { useEffect } from "react";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import calendar from "../../assets/icon8/calendar.png";
import marker from "../../assets/icon8/marker.png";
import call from "../../assets/icon8/call.png";

import {
  getTournaments,
  selectTournaments,
  // selectTournamentsError,
  selectTournamentsStatus,
} from "../../redux/features/tournaments/tournamentsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PARTICIPATED_TOURNAMENT, TOURNAMENT } from "../../shared/constant/routes";
import { TOURNAMENT_IMG_URL } from "../../shared/constant/setting";
import { formatDate } from "../../shared/utils";
import { calendarOutline } from "ionicons/icons";

const PAGE_TITLE = "Turnamen";

const TournamentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Home
  const tournaments = useAppSelector(selectTournaments);
  const tournamentsStatus = useAppSelector(selectTournamentsStatus);
  // const tournamentsError = useAppSelector(selectTournamentsError);

  useEffect(() => {
    if (tournamentsStatus.list === "idle") {
      dispatch(getTournaments());
    } else if (tournamentsStatus.list === "success") {
      // console.log(homeStatus);
    } else if (tournamentsStatus.list === "failed") {
      // console.log(homeError);
    }
  }, [tournamentsStatus]);

  return (
    <IonPage className="tournament">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                history.push(PARTICIPATED_TOURNAMENT);
              }}
            >
              <IonIcon icon={calendarOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="grey" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="mx-2">
          {tournamentsStatus.list === "success" &&
            tournaments.length > 0 &&
            tournaments.map((item, index) => {
              return (
                <IonCard
                  className="my-4 rounded-2xl"
                  key={index}
                  onClick={() => {
                    history.push(`${TOURNAMENT}/${item.id}`);
                  }}
                >
                  <div className="img-container">
                    <img
                      alt=" "
                      src={`${TOURNAMENT_IMG_URL}/${item.banner_img}`}
                    />
                  </div>

                  <IonCardHeader>
                    <IonCardTitle>{item.name}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={calendar} />
                        </IonCol>
                        <IonCol>
                          {formatDate(item.from)} s/d{" "}
                          {item.to ? formatDate(item.to) : "Selesai"}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={marker} />
                        </IonCol>
                        <IonCol>{item.location}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="1">
                          <img className="icon8" alt=" " src={call} />
                        </IonCol>
                        <IonCol>{item.contact_person}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TournamentPage;
