import "./profile.css";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  getProfile,
  logOut,
  resetAccount,
  // selectAccountError,
  selectAccountStatus,
  selectAccountUser,
} from "../../redux/features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  settingsOutline,
  createOutline,
  logOutOutline,
  cardOutline,
} from "ionicons/icons";
import {
  HOME,
  LOGIN,
  MEMBER_CARD,
  PROFILE,
  TABS,
} from "../../shared/constant/routes";
import { USER_IMG_URL } from "../../shared/constant/setting";
import { formatDate, removeToken } from "../../shared/utils";

const PAGE_TITLE = "Profil";

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();

  const user = useAppSelector(selectAccountUser);
  const accountStatus = useAppSelector(selectAccountStatus);
  // const accountError = useAppSelector(selectAccountError);

  useEffect(() => {
    if (!isLoaded && accountStatus.profile !== "idle") dispatch(resetAccount());
  }, []);

  useEffect(() => {
    if (accountStatus.profile === "idle" && !isLoaded) {
      dispatch(getProfile());
      setIsLoaded(true);
    }
  }, [accountStatus.profile]);

  useEffect(() => {
    if (accountStatus.profile === "success") {
      // console.log(user);
    } else if (accountStatus.profile === "failed") {
      // console.log(accountError.profile);
    }
  }, [accountStatus.profile]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={settingsOutline} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton
              color="info"
              onClick={() => {
                history.push(MEMBER_CARD);
              }}
            >
              <IonIcon icon={cardOutline}></IonIcon>
            </IonFabButton>
            <IonFabButton
              color="secondary"
              onClick={() => {
                history.push(`${PROFILE}/edit`);
              }}
            >
              <IonIcon icon={createOutline}></IonIcon>
            </IonFabButton>
            <IonFabButton
              color="danger"
              onClick={() => {
                removeToken();
                dispatch(resetAccount());
                dispatch(logOut());
                history.push(`${TABS}${HOME}`);
              }}
            >
              <IonIcon icon={logOutOutline}></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>

        <div className="about-header">
          <IonGrid>
            <IonRow>
              <IonCol>
                <img
                  src={`${USER_IMG_URL}/${user?.profile_pic ?? "default.png"}`}
                  alt={user?.name}
                />
                <p className="text-m font-bold mt-2 text-white">
                  {user?.member?.name}
                </p>
                <p className="text-sm mt-2 text-white">{user?.member?.email}</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol size="4" className="text-sm" no-padding>
                No Telepon
              </IonCol>
              <IonCol size="8" className="text-sm font-bold" no-padding>
                {user?.member?.phone ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol size="4" className="text-sm" no-padding>
                Alamat
              </IonCol>
              <IonCol
                size="8"
                className="line-break text-sm font-bold"
                no-padding
              >
                {user?.member?.address ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol size="4" className="text-sm" no-padding>
                Tempat/Tgl Lahir
              </IonCol>
              <IonCol
                size="8"
                className="line-break text-sm font-bold"
                no-padding
              >
                {user?.member?.place_of_birth ?? "-"},{" "}
                {user?.member?.date_of_birth
                  ? formatDate(user.member.date_of_birth)
                  : "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol size="4" className="text-sm" no-padding>
                No KTP
              </IonCol>
              <IonCol
                size="8"
                className="line-break text-sm font-bold"
                no-padding
              >
                {user?.member?.nationality_id ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;
