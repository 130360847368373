import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { client } from "../../../services/api";
import { Order, ValidationError } from "../../../shared/interfaces";
import { OrderPayload } from "../../../shared/interfaces/index";
import { AppState } from "../../store";

export interface OrderState {
  list: Order[];
  item: Order | null;
  status: {
    list: string;
    item: string;
    post: string;
    update: string;
    action: string;
  };
  error: {
    list: string | null;
    item: string | null;
    post: string | ValidationError | null;
    update: string | ValidationError | null;
    action: string | ValidationError | null;
  };
}

const initialState: OrderState = {
  list: [],
  item: null,
  status: {
    list: "idle",
    item: "idle",
    post: "idle",
    update: "idle",
    action: "idle",
  },
  error: { list: null, item: null, post: null, update: null, action: null },
};

export const getOrders = createAsyncThunk("orders/getOrders", async () => {
  const response = (await client.get(`order`)).data;
  return response.data.order;
});

export const getOrder = createAsyncThunk(
  "orders/getOrder",
  async (id: number) => {
    const response = (await client.get(`order/${id}`)).data;
    return response.data.order;
  }
);

export const postOrder = createAsyncThunk(
  "orders/postOrder",
  async (payload: OrderPayload) => {
    const response = (await client.post("order", payload)).data;
    return response;
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async (id: number) => {
    const response = (await client.post(`order/${id}`, { _method: "put" }))
      .data;
    return response;
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrder(state) {
      state.status = {
        list: "idle",
        item: "idle",
        post: "idle",
        update: "idle",
        action: "idle",
      };
      state.error = {
        list: null,
        item: null,
        post: null,
        update: null,
        action: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.status.list = "loading";
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.status.list = "failed";
        state.error.list = action.error.message ?? null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.status.list = "success";
        state.list = action.payload;
      })

      .addCase(getOrder.pending, (state, action) => {
        state.status.item = "loading";
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.status.item = "failed";
        state.error.item = action.error.message ?? null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.status.item = "success";
        state.item = action.payload;
      })

      .addCase(postOrder.pending, (state, action) => {
        state.status.post = "loading";
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.status.post = "failed";
        state.error.post = action.error.message ?? null;
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.post = "success";
          state.item = response.data.order;
        } else {
          state.status.post = "failed";
          state.error.post = response.errors ?? response.message ?? null;
        }
      })

      .addCase(updateOrder.pending, (state, action) => {
        state.status.update = "loading";
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.status.update = "failed";
        state.error.update = action.error.message ?? null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.success) {
          state.status.update = "success";
          state.item = response.data.order;
        } else {
          state.status.update = "failed";
          state.error.update = response.errors ?? response.message ?? null;
        }
      });
  },
});

export const selectOrders = (state: AppState) => state.orders.list;
export const selectOrder = (state: AppState) => state.orders.item;
export const selectOrdersStatus = (state: AppState) => state.orders.status;
export const selectOrdersError = (state: AppState) => state.orders.error;

export const { resetOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
