import "./community.css";

import { useEffect, useState } from "react";

import { Browser } from "@capacitor/browser";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import placeholder from "../../assets/img/placeholder.png";
import {
  getCommunity,
  selectCommunity,
  // selectCommunityError,
  selectCommunityStatus,
} from "../../redux/features/community/communitySlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { COMMUNITY_IMG_URL } from "../../shared/constant/setting";
import { mapOutline } from "ionicons/icons";

const PAGE_TITLE = "Informasi Komunitas";

const CommunityPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  // COMMUNITY
  const community = useAppSelector(selectCommunity);
  const communityStatus = useAppSelector(selectCommunityStatus);
  // const communityError = useAppSelector(selectCommunitysError);

  useEffect(() => {
    if (communityStatus.item === "idle" && !isLoaded) {
      dispatch(getCommunity());
      setIsLoaded(true);
    } else if (communityStatus.item === "success") {
      // console.log(informationStatus);
    } else if (communityStatus.item === "failed") {
      // console.log(informationError);
    }
  }, [communityStatus.item]);

  return (
    <IonPage className="community">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="img-container">
          <img
            src={
              community?.logo
                ? `${COMMUNITY_IMG_URL}/${community?.logo}`
                : placeholder
            }
            alt="img"
          />
        </div>

        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={async () => {
              await Browser.open({
                url: `https://www.google.com/maps/search/?api=1&query=${community?.coordinate}`,
              });
            }}
          >
            <IonIcon icon={mapOutline} />
          </IonFabButton>
        </IonFab>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-lg font-bold" no-padding>
                {community?.name ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                No Telp Komunitas
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="line-break text-sm font-bold" no-padding>
                {community?.phone ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Alamat Kantor Komunitas
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {community?.address ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Email Komunitas
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="line-break text-sm font-bold" no-padding>
                {community?.email ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Profil Singkat Komunitas
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {community?.profile_desc ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default CommunityPage;
