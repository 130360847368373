import "./login.css";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { IonButton, IonContent, IonHeader, IonPage } from "@ionic/react";

import pbsi from "../../assets/img/pbsi.webp";
import wave from "../../assets/img/wave.svg";
import { Input } from "../../components/Form";
import {
  getProfile,
  login,
  resetAccount,
  selectAccountError,
  selectAccountRole,
  selectAccountStatus,
  selectIsAuthenticated,
} from "../../redux/features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { HOME, REGISTER, TABS } from "../../shared/constant/routes";
import { REQUIRED } from "../../shared/constant/validation";
import useShowToast from "../../shared/hooks/useShowToast";
import { LoginPayload } from "../../shared/interfaces";

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    // setValue,
    // register,
    // getValues,
    formState: { errors },
  } = useForm<LoginPayload>({
    defaultValues: {
      username: "anggota",
      // username: "dj_randeez2@yahoo.com",
      password: "password",
    },
  });

  const onSubmit = (data: any) => {
    // console.log(data);
    dispatch(login(data));
  };

  const isAuth = useAppSelector(selectIsAuthenticated);
  const role = useAppSelector(selectAccountRole);
  const accountStatus = useAppSelector(selectAccountStatus);
  const accountError = useAppSelector(selectAccountError);

  useEffect(() => {
    if (accountStatus.login === "success") {
      presentToast("Login berhasil");
      dispatch(resetAccount());
    } else if (accountStatus.login === "failed") {
      // console.log(accountError.login);
      presentToast("Login gagal, " + accountError.login, "danger");
      dispatch(resetAccount());
    }
  }, [accountError.login, accountStatus.login, presentToast]);

  useEffect(() => {
    if (isAuth) {
      if (role === "anggota") {
        history.push(`${TABS}${HOME}`);
      }
    }
  }, [history, isAuth]);

  // get profile
  useEffect(() => {
    if (!isAuth) {
      dispatch(getProfile());
    }
  }, []);
  // if success then redirect to home
  useEffect(() => {
    if (accountStatus.profile === "success") {
      // logic redirect antara user atau admin
      if (role === "anggota") {
        history.push(`${TABS}${HOME}`);
      }
    }
  }, [history, role, accountStatus.profile]);

  return (
    <IonPage>
      <IonHeader translucent> </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense"> </IonHeader>

        <div
          style={{ backgroundImage: `url(${pbsi})` }}
          className="bg-center bg-no-repeat"
        >
          <div
            style={{ backgroundImage: `url(${wave})` }}
            className="bg-bottom bg-no-repeat"
          >
            <div className="flex flex-col items-center justify-center h-screen mx-4">
              <p className="font-bold text-2xl text-center mb-4">
                Persatuan Bulu Tangkis Pekanbaru
              </p>

              <p className="text-center mb-10">
                Silahkan Login Untuk Melanjutkan.
              </p>

              <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
                <Input
                  name="username"
                  label="Username"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <Input
                  name="password"
                  type="password"
                  label="Password"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />

                <IonButton
                  color={"primary"}
                  expand="block"
                  type="submit"
                  className="mt-10"
                  disabled={accountStatus.login === "loading"}
                >
                  Login
                </IonButton>

                <IonButton
                  color={"secondary"}
                  expand="block"
                  type="button"
                  className="mt-4"
                  onClick={() => {
                    history.push(REGISTER);
                  }}
                >
                  Daftar Sebagai Anggota
                </IonButton>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
