import { FC } from "react";
import { IonItem, IonLabel, IonInput, IonNote } from "@ionic/react";
import { Controller, DeepMap, FieldError } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  name: string;
  type?: "text" | "password" | "email" | "number" | "search" | "tel" | "url";
  minValue?: number;
  control?: any;
  label?: string;
  note?: string;
  rules?: Object;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

export const Input: FC<Props> = ({
  name,
  type,
  minValue,
  control,
  label,
  note,
  rules,
  errors,
}) => {
  return (
    <>
      <IonItem>
        <IonLabel position="floating">{label ?? name}</IonLabel>
        <Controller
          render={({ field, field: { onChange } }) => (
            <IonInput
              {...field}
              type={type ?? undefined}
              min={type === "number" ? minValue ?? undefined : undefined}
              onIonChange={onChange}
              aria-invalid={errors && errors[name] ? "true" : "false"}
              aria-describedby={`${name}Error`}
            />
          )}
          control={control}
          name={name}
          rules={rules}
        />
        {note ? <IonNote slot="helper">{note}</IonNote> : <></>}
      </IonItem>
      <ErrorMessage
        errors={errors}
        name={name}
        as={<IonNote slot="error" color="danger" />}
      />
    </>
  );
};
