import "./order.css";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  IonBadge,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  getOrders,
  resetOrder,
  selectOrders,
  // selectOrdersError,
  selectOrdersStatus,
} from "../../redux/features/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ORDER } from "../../shared/constant/routes";
import { formatNumber } from "../../shared/utils";

const PAGE_TITLE = "Pemesanan";

const OrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  // ORDER LIST
  const orders = useAppSelector(selectOrders);
  const ordersStatus = useAppSelector(selectOrdersStatus);
  // const ordersError = useAppSelector(selectOrdersError);

  useEffect(() => {
    if (!isLoaded && ordersStatus.list !== "idle") dispatch(resetOrder());
  }, []);

  useEffect(() => {
    if (ordersStatus.list === "idle" && !isLoaded) {
      dispatch(getOrders());
      setIsLoaded(true);
    } else if (ordersStatus.list === "success") {
      // console.log(facilitiesStatus.item);
    } else if (ordersStatus.list === "failed") {
      // console.log(facilitiesError.item);
    }
  }, [ordersStatus.list]);

  const orderStatus = (status: string) => {
    if (status === "1") {
      return (
        <IonBadge className="text-xs" color="danger">
          Menunggu Pembayaran
        </IonBadge>
      );
    } else if (status === "2") {
      return (
        <IonBadge className="text-xs" color="warning">
          Menunggu Diproses
        </IonBadge>
      );
    } else if (status === "3") {
      return (
        <IonBadge className="text-xs" color="success">
          Diproses
        </IonBadge>
      );
    } else if (status === "4") {
      return (
        <IonBadge className="text-xs" color="success">
          Dikirim
        </IonBadge>
      );
    } else if (status === "5") {
      return (
        <IonBadge className="text-xs" color="success">
          Selesai
        </IonBadge>
      );
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          {orders.length > 0 ? (
            orders.map((item, i) => {
              return (
                <IonItem
                  button
                  key={i}
                  onClick={() => {
                    history.push(`${ORDER}/${item.id}`);
                  }}
                >
                  <IonLabel>
                    <div className="text-xs">
                      No Invoice <b>#{item.invoice_no}</b>
                    </div>
                    <div className="mt-1 text-md font-bold">
                      Rp. {formatNumber(item.total_transaction)}
                    </div>
                    <div className="mt-1 text-xs">
                      {item.order_details?.length ?? 0} Barang
                    </div>
                    <div className="mt-2">{orderStatus(item.status)}</div>
                  </IonLabel>
                </IonItem>
              );
            })
          ) : (
            <IonItem>
              <IonLabel>Belum ada pesanan</IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default OrderPage;
