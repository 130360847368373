import "./attribute-detail.css";

import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import placeholder from "../../assets/img/placeholder.png";
import {
  getAttribute,
  resetAttribute,
  selectAttribute,
  // selectAttributesError,
  selectAttributesStatus,
} from "../../redux/features/attributes/attributesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatNumber, gramToKilogram } from "../../shared/utils";
import { ATTRIBUTE_IMG_URL } from "../../shared/constant/setting";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Form";
import {
  postCart,
  resetCart,
  selectCartError,
  selectCartStatus,
} from "../../redux/features/cart/cartSlice";
import { REQUIRED } from "../../shared/constant/validation";
import { CartPayload } from "../../shared/interfaces";
import useShowToast from "../../shared/hooks/useShowToast";

const PAGE_TITLE = "Rincian Kegiatan";

const AttributeDetailPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentToast] = useShowToast();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const match = useRouteMatch<{ id: string }>();

  // ATTRIBUTE
  const attribute = useAppSelector(selectAttribute);
  const attributeStatus = useAppSelector(selectAttributesStatus);
  // const attributeError = useAppSelector(selectAttributesError);

  useEffect(() => {
    if (!isLoaded && attributeStatus.item !== "idle")
      dispatch(resetAttribute());
  }, []);

  useEffect(() => {
    if (attributeStatus.item === "idle" && !isLoaded) {
      const { id } = match.params;
      dispatch(getAttribute(Number(id)));
      setIsLoaded(true);
    } else if (attributeStatus.item === "success") {
      // console.log(informationStatus);
    } else if (attributeStatus.item === "failed") {
      // console.log(informationError);
    }
  }, [attributeStatus.item]);

  // POST CART
  const cartStatus = useAppSelector(selectCartStatus);
  const cartError = useAppSelector(selectCartError);

  useEffect(() => {
    if (!isLoaded && attributeStatus.list !== "idle") {
      dispatch(resetAttribute());
    }
  }, []);

  useEffect(() => {
    if (cartStatus.post === "success") {
      presentToast("Attribut berhasil ditambahkan ke keranjang.");
      history.goBack();
      dispatch(resetCart());
    } else if (cartStatus.post === "failed") {
      if (typeof cartError.post == "string") {
        presentToast(cartError.post, "danger");
      } else if (typeof cartError.post == "object") {
        // if validation error
        for (let key in cartError.post) {
          presentToast(cartError.post[key][0], "danger");
        }
      } else {
        presentToast("Attribut gagal ditambahkan ke keranjang", "danger");
      }
      dispatch(resetCart());
    }
  }, [cartError.post, cartStatus.post, presentToast]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CartPayload>({
    defaultValues: {
      _method: "POST",
      attribute_id: 0,
      quantity: 1,
    },
  });

  const onSubmit = (data: any) => {
    dispatch(postCart({ ...data, attribute_id: attribute?.id ?? 0 }));
  };

  return (
    <IonPage className="attribute-detail">
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{PAGE_TITLE}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color={"primary"}>
            <IonTitle size="large">{PAGE_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="img-container">
          <img
            src={
              attribute?.image
                ? `${ATTRIBUTE_IMG_URL}/${attribute?.image}`
                : placeholder
            }
            alt="img"
          />
        </div>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-lg font-bold" no-padding>
                {attribute?.name ?? "-"}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-md font-bold" no-padding>
                Rp {formatNumber(attribute?.price) ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Berat
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="line-break text-sm font-bold" no-padding>
                {gramToKilogram(attribute?.weight_gram) ?? ""}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem no-padding>
          <IonGrid>
            <IonRow>
              <IonCol className="text-xs" no-padding>
                Deskripsi Attribut
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text-sm font-bold" no-padding>
                {attribute?.description ?? "-"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>

      <IonFooter>
        <form style={{ width: "90%" }} onSubmit={handleSubmit(onSubmit)}>
          <IonGrid>
            <IonRow>
              <IonCol size="6" no-padding>
                <Input
                  name="quantity"
                  type="number"
                  label="Jumlah Pembelian"
                  control={control}
                  rules={{ required: REQUIRED }}
                  errors={errors}
                />
              </IonCol>
              <IonCol size="6" no-padding>
                <IonButton
                  className="mt-6"
                  color={"primary"}
                  expand="block"
                  type="submit"
                  disabled={cartStatus.post === "loading"}
                >
                  Tambahkan<br></br>Keranjang
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonFooter>
    </IonPage>
  );
};

export default AttributeDetailPage;
